const endpoints = {
  login: "/auth/login",
  validateToken: "/auth/validate-token",

  config: "/config",

  customPermissions: '/users/custom-permissions',

  dashboard: "/dashboard",
  dashboardBalancePerCoin: "/dashboard/balance-per-coin",
  dashboardVolumeOfOperations: "/dashboard/volume-of-operations",
  dashboardVolumeOfOperationsPerCoin:
    "/dashboard/volume-of-operations-per-coin",
  user: "/user",
  users: "/users",
  usersProfile: "/users/profile",
  allUsers: "/users/all",
  usersExport: "/users/export",
  usersRegimen: "/users/regimen",
  usersCognitoProfile: "/users/cognito-profile",
  enableRiskUser: "/users/enable-risk-user",
  whiteList: "/users/white-list",

  userIdentity: "/users/identity",
  usersConfiguration: "/users/configuration",
  userIdentityReset: "/users/identity/reset",
  changeAccountEnablement: "/users/enablement",
  changeTypeUser: "/users/type-user",
  sendSMS: "/users/sms",
  allOperations: "/operations/all",
  operations: "/operations",
  operationUuid: "/operations/{uuid}",
  operationsGift: "/operations/gift",
  operationsGiftDistribution: "/operations/gift-distribution",
  operationsReceiveBlockchain: "/operations/receive-blockchain",
  operationDepositApply: "/operations/deposit/{uuid}/apply",
  operationWithdrawApply: "/operations/withdraw/{uuid}/apply",
  operationWithdrawProviderApply: "/operations/withdraw/{uuid}/apply-provider",
  operationsSendBlockchainApply: "/operations/send-blockchain/{uuid}/apply",
  operationsReceiveBlockchainApply: "/operations/receive-blockchain/{uuid}/apply",
  operationsExportROS: "/operations/export/ROS",
  operationsExportCommissions: "/operations/export/commissions",
  operationsExportOperations: "/operations/export",

  allCoins: "/coins/all",
  allCoinsBlockchain: "/coins/blockchain",

  authToken: "/auth/token",
  authPresignedRegimen: "/auth/presigned-regimen/${userId}",
  authPresignedOperationWithdraw:
    "/auth/presigned-operations-withdraw/${operationId}",

  notes: "/notes/{userId}",
  downloadRegimenFilesFromNote: "/notes/{noteId}/download-regimen-files",

  giftCards: "/giftCards",
};

export default endpoints;
