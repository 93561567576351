import API from 'api/api'
import endpoints from 'api/endpoints/lambda'
import { toast } from 'react-toastify'
import { ConfigTypes } from 'ts/enums/config'

export const listConfig = () => async (dispatch: any) => {
  try {
    const { response } = await API.request({
      path: endpoints.config,
      method: 'GET',
      hasToken: true,
    })

    if (!response?.success) throw Error(response?.error)


    dispatch({
      type: ConfigTypes.GET_COUNTRIES,
      data: response.data.countries,
    })

    dispatch({
      type: ConfigTypes.GET_REGIMENS,
      data: response.data.regimens,
    })

    dispatch({
      type: ConfigTypes.GET_MARITAL_STATUS,
      data: response.data.maritalStatus,
    })

    dispatch({
      type: ConfigTypes.GET_OCCUPATIONS,
      data: response.data.occupations,
    })

    dispatch({
      type: ConfigTypes.GET_COMMERCIAL_SECTORS,
      data: response.data.commercialSectors,
    })
  } catch (error) {
    toast.error('No se ha obtenido la configuracion')
  }
}
