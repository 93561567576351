export const API = process.env.REACT_APP_API || "";

export enum MICROSERVICES {
  MS_USER_PROFILE = "ms-user-profile",
  MS_CAMBIOS = "ms-cambios",
  LAMBDA_ADMIN = "fluyez",
}

export const OBJ_STATUS_GIFT = {
  PENDING: "Pending",
  REDEEMED: "Redeemed",
  DISABLED: "Disabled",
};

export const OBJ_TYPE_GIFT = {
  VIRTUAL: "Virtual",
  PHYSICAL: "Fisico",
};

export const STATUS_GIFT = [
  { value: OBJ_STATUS_GIFT.PENDING, label: OBJ_STATUS_GIFT.PENDING },
  { value: OBJ_STATUS_GIFT.REDEEMED, label: OBJ_STATUS_GIFT.REDEEMED },
  { value: OBJ_STATUS_GIFT.DISABLED, label: OBJ_STATUS_GIFT.DISABLED },
];
export const TYPE_GIFT = [
  { value: OBJ_TYPE_GIFT.VIRTUAL, label: OBJ_TYPE_GIFT.VIRTUAL },
  { value: OBJ_TYPE_GIFT.PHYSICAL, label: OBJ_TYPE_GIFT.PHYSICAL },
];
export const TYPE_USERS = {
  1: "Usuario",
  7: "Ministerio de Justicia", 
  8: "Premium"
}